import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ConfigEntity } from "@domain/entities/processes/config.entity";
import { FormatTimeService } from "@utils/format-time/format-time.service";
import { ConfigCardType } from "./config-card-type.enum";

@Component({
  selector: "config-card",
  templateUrl: "./config-card.component.html",
  styleUrls: ["./config-card.component.scss"],
})
export class ConfigCardComponent implements OnChanges {
  get cardType(): typeof ConfigCardType {
    return ConfigCardType;
  }
  @Input() type: ConfigCardType = ConfigCardType.INFO;
  @Input({ required: true }) config: ConfigEntity = {} as ConfigEntity;
  @Input() checked: boolean = false;
  @Output() sendId: EventEmitter<number> = new EventEmitter();
  @Output() closeEmitter: EventEmitter<void> = new EventEmitter();
  @Output() editEmitter: EventEmitter<void> = new EventEmitter();
  @Output() deleteEmitter: EventEmitter<void> = new EventEmitter();
  formatedTime: string = "";

  constructor(private formatTime: FormatTimeService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.updateTimeFormat();
    }
  }

  updateTimeFormat(): void {
    this.formatedTime = this.formatTime.durationToFormattedTime(
      this.config.duration,
      this.config.duration_minutes
    );
  }

  idEmit(): void {
    this.sendId.emit(this.config.id);
  }

  iconClick(): void {
    switch (this.type) {
      case ConfigCardType.LIST_ITEM:
        this.closeEmitter.emit();
    }
  }

  closeClick(): void {
    this.closeEmitter.emit();
  }

  editClick(): void {
    this.editEmitter.emit();
  }

  deleteClick(): void {
    this.deleteEmitter.emit();
  }
}
