import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { GuardianAndTeamInterface } from "@public-interface/endpoints-bodies/is-guardian-removable.interface";
import { Observable } from "rxjs";

@Injectable()
export class RemoveGuardianByIdUseCase
  implements UseCase<GuardianAndTeamInterface, { detail: string }>
{
  constructor(private userRepository: UserRepository) {}

  execute(body: GuardianAndTeamInterface): Observable<{ detail: string }> {
    return this.userRepository.removeGuardian(body);
  }
}
