import { UserRepository } from "@domain/repositories/user/user.repository";
import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { ConfigEntity } from "@domain/entities/processes/config.entity";
import { Observable } from "rxjs";

@Injectable()
export class GetTeamConfigurationsUseCase
  implements UseCase<number, ConfigEntity[]>
{
  constructor(private userRepository: UserRepository) {}

  execute(teamId: number): Observable<ConfigEntity[]> {
    return this.userRepository.listConfigurations({ team_id: teamId });
  }
}
