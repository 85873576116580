import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { GuardianEntity } from "@domain/entities/guardians/guardian.entity";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { Observable, map } from "rxjs";

@Injectable()
export class GetTeamGuardiansAndInvitationsUseCase
  implements
    UseCase<
      { id: number },
      { teamName: string; guardians: GuardianEntity[]; invitations: string[] }
    >
{
  constructor(private userRepository: UserRepository) {}

  execute(params: { id: number }): Observable<{
    teamName: string;
    guardians: GuardianEntity[];
    invitations: string[];
  }> {
    return this.userRepository.getTeam(params).pipe(
      map((response) => {
        return {
          teamName: response.name,
          guardians: response.guardian_pool,
          invitations: response.invitations,
        };
      })
    );
  }
}
