import { Mapper } from "@core-interface/mapper";
import { UserModel } from "@datasource/user/remote/dto/user.model";
import { UserEntity } from "@entities/user/user.entity";
import { UserRole } from "@public-interface/user-role";

export class UserImplementationRepositoryMapper extends Mapper<
  UserModel,
  UserEntity
> {
  mapTo(param: UserEntity): UserModel {
    return {
      email: param.email,
      personal_document_id: param.personal_document_id,
      first_name: param.first_name,
      last_name: param.last_name,
      phone: param.phone,
      num_of_guardians: param.num_of_guardians,
      num_of_pending_invitations: param.num_of_pending_invitations,
      role: param.role,
      code: param.code,
    };
  }
  mapFrom(param: UserModel): UserEntity {
    return {
      email: param.email,
      personal_document_id: param.personal_document_id,
      first_name: param.first_name,
      last_name: param.last_name,
      phone: param.phone,
      num_of_guardians: param.num_of_guardians,
      num_of_pending_invitations: param.num_of_pending_invitations,
      role: param.role as UserRole,
      code: param.code,
    };
  }
}
