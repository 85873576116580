import { UserRepository } from "@domain-repositories/user/user.repository";
import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { Observable } from "rxjs";

@Injectable()
export class DeleteLocalUserInfoUseCase implements UseCase<null, boolean> {
  constructor(private userRepository: UserRepository) {}

  execute(): Observable<boolean> {
    return this.userRepository.deleteLocalUserInfo();
  }
}
