import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OneBtnDialogData } from "./one-btn-dialog-data.interface";

@Component({
  selector: "one-btn-dialog",
  templateUrl: "./one-btn-dialog.component.html",
  styleUrls: ["./one-btn-dialog.component.scss"],
})
export class OneBtnDialogComponent {
  @Output() event$: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public dialogRef: MatDialogRef<OneBtnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OneBtnDialogData
  ) {}

  onClick() {
    if (this.data.buttonFn) {
      this.event$.emit();
    } else {
      this.dialogRef.close();
    }
  }
}
