export const regex = {
  emailRg: "[a-zA-Z0-9._-]+@[a-z._-]+\\.+[a-zA-Z]+",
  idNumberRg: "^[XYZ]?\\d{5,8}[A-Z]$",
  passRg:
    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[/:()&@"?!'[{}#%^*+=_¿¡|<>$`£¥€~-]).{8,}/,
  phoneRg: /^(00|\+)(34\d{9}|(?!(?:34)\d)\d{2}\d{9,17})$/,
  urlRg: /^https?:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+.*$/,
};

export const storageKey =
  "05d8f89ea4d8f323eb9f3ae4f86819f37653dacac0b172a74129d8525eb6ec83";
