import { AppVersion } from "@utils/app-version/app-version.interface";
import { AppVersionService } from "@utils/app-version/app-version.service";
import { Component, Input } from "@angular/core";

@Component({
  selector: "custom-footer",
  templateUrl: "./custom-footer.component.html",
  styleUrls: ["./custom-footer.component.scss"],
})
export class CustomFooterComponent {
  @Input() darkTheme: boolean = false;
  appVersion: AppVersion;

  constructor(private appVersionService: AppVersionService) {
    this.appVersion = this.appVersionService.getAppVersion();
  }
}
