import { environment } from "@environments/environment";
import { AppVersion } from "./app-version.interface";
import { Injectable } from "@angular/core";
import packageJson from "package.json";

@Injectable({
  providedIn: "root",
})
export class AppVersionService {
  constructor() {}

  getAppVersion(): AppVersion {
    const appVersion: AppVersion = {
      name: packageJson.name,
      version: packageJson.version,
      environment: environment.production,
    };
    return appVersion;
  }
}
