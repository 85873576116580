import { Location } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "return-back",
  templateUrl: "./return-back.component.html",
  styleUrls: ["./return-back.component.scss"],
})
export class ReturnBackComponent {
  constructor(private location: Location, private router: Router) {}

  @Input() urlToNavigate = "";

  back(url?: string): void {
    if (url) {
      this.router.navigate([url]);
    } else {
      this.location.back();
    }
  }
}
