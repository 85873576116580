import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "user-profile-img",
  templateUrl: "./user-profile-img.component.html",
  styleUrls: ["./user-profile-img.component.scss"],
})
export class UserProfileImgComponent implements OnInit {
  @Input() userName: {
    firstName: string;
    lastName: string;
  } = { firstName: "", lastName: "" };
  @Input() size: number = 120;
  @Input() textSize: number = 36;
  initials: string = "";

  ngOnInit(): void {
    this.initials = this.userName.firstName
      .charAt(0)
      .concat(this.userName.lastName.charAt(0));
  }
}
