import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-info-loading-dialog",
  templateUrl: "./info-loading-dialog.component.html",
  styleUrls: ["./info-loading-dialog.component.scss"],
})
export class InfoLoadingDialogComponent {
  message: string = "";

  constructor(
    public dialogRef: MatDialogRef<InfoLoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {
    this.dialogRef.disableClose = true;
    this.message = data.message;
  }
}
