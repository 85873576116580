<footer
  class="text-center text-small py-2 mt-8 font-['Poppins']"
  [ngClass]="darkTheme ? 'bg-tertiary text-white' : ''"
>
  <p class="flex place-content-center gap-2">
    <span>{{ appVersion.name.toUpperCase() }}</span
    >-<span>v {{ appVersion.version }}</span>
    <span>({{ appVersion.environment ? "PROD" : "DEV" }})</span>
  </p>
</footer>
