import { UserRepository } from "@domain-repositories/user/user.repository";
import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { IntegrationEntity } from "@domain/entities/app/integration.entity";
import { CreateServiceInterface } from "@public-interface/endpoints-bodies/create-service.interface";
import { Observable } from "rxjs";

@Injectable()
export class CreateServiceUseCase
  implements UseCase<CreateServiceInterface, IntegrationEntity>
{
  constructor(private userRepository: UserRepository) {}

  execute(body: CreateServiceInterface): Observable<IntegrationEntity> {
    return this.userRepository.createService(body);
  }
}
