<h4 class="mb-4">{{ data.title }}</h4>

<div [innerHTML]="data.text"></div>

<div class="sm:flex sm:justify-center lg:justify-end w-full mt-8">
  <custom-button
    [type]="data.buttonType"
    [text]="data.buttonText"
    [isLoading]="data.isButtonLoading ?? false"
    (click)="onClick()"
  />
</div>
