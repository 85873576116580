import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { Observable } from "rxjs";

@Injectable()
export class DeleteAccountUseCase implements UseCase<null, { detail: string }> {
  constructor(private userRepository: UserRepository) {}

  execute(): Observable<{ detail: string }> {
    return this.userRepository.deleteAccount();
  }
}
