import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { TeamEntity } from "@entities/user/team.entity";
import { UserRepository } from "@domain-repositories/user/user.repository";
import { Observable, map } from "rxjs";

@Injectable()
export class GetUserFirstTeamUseCase implements UseCase<null, TeamEntity> {
  constructor(private userRepository: UserRepository) {}

  execute(): Observable<TeamEntity> {
    return this.userRepository.teamsList().pipe(
      map((teams) => {
        return teams[0];
      })
    );
  }
}
