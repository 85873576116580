import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { UserEntity } from "@entities/user/user.entity";
import { UserRepository } from "@domain-repositories/user/user.repository";
import { Observable } from "rxjs";

@Injectable()
export class GetUserInformationUseCase implements UseCase<null, any> {
  constructor(private userRepository: UserRepository) {}

  execute(): Observable<UserEntity> {
    return this.userRepository.me();
  }
}
