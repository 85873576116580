import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { ConfigEntity } from "@domain/entities/processes/config.entity";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { CreateConfigInterface } from "@public-interface/endpoints-bodies/create-config.interface";
import { Observable } from "rxjs";

@Injectable()
export class CreateConfigUseCase
  implements UseCase<CreateConfigInterface, ConfigEntity>
{
  constructor(private userRepository: UserRepository) {}

  execute(body: CreateConfigInterface): Observable<ConfigEntity> {
    return this.userRepository.createConfig(body);
  }
}
