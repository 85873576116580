import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CustomButtonType } from "./custom-button-type";

@Component({
  selector: "custom-button",
  templateUrl: "./custom-button.component.html",
  styleUrls: ["./custom-button.component.scss"],
})
export class CustomButtonComponent implements OnInit {
  get btnType(): typeof CustomButtonType {
    return CustomButtonType;
  }
  @Input({ required: true }) type: CustomButtonType = "" as CustomButtonType;
  @Input() small: boolean = false;
  @Input() text: string = "";
  @Input() disabledBtn: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() icon: string = "";
  @Input() iconSize: number = 20;
  @Input() iconColor: string = "";
  @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();
  iconColorTailwind: string = "";
  buttonWidth: number = 0;

  ngOnInit(): void {
    if (this.iconColor) {
      this.iconColorSet();
    }
  }

  clickEventEmitter() {
    this.buttonWidth = document.getElementById(this.text)!.offsetWidth;
    this.clickEvent.emit();
  }

  iconColorSet(): void {
    this.iconColorTailwind = `text-${this.iconColor}`;
  }
}
