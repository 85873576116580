<button
  [id]="text"
  [disabled]="disabledBtn"
  [ngClass]="{
    'btn-primary': type === btnType.Primary,
    'btn-secondary': type === btnType.Secondary,
    'btn-tertiary': type === btnType.Tertiary,
    'btn-flat': type === btnType.Flat,
    'h-10': small === true
  }"
  [ngStyle]="isLoading ? { 'width.px': buttonWidth } : {}"
  (click)="clickEventEmitter()"
>
  <div *ngIf="!isLoading" class="flex place-items-center gap-2">
    <custom-icon
      *ngIf="icon"
      [icon]="icon"
      [size]="iconSize"
      [ngClass]="iconColor ? iconColorTailwind : ''"
    />
    <p [ngClass]="{ 'text-small': small }">{{ text }}</p>
  </div>
  <ng-container *ngIf="isLoading"
    ><img
      class="w-6"
      [src]="
        type === btnType.Secondary
          ? 'assets/gifs/black_spinner.gif'
          : 'assets/gifs/white_spinner.gif'
      "
  /></ng-container>
</button>
