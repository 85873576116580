import { Injectable } from "@angular/core";
import { SecurityLevelDataSource } from "@data/datasource/security-levels/source/security-level.datasource";
import { SecurityLevelEntity } from "@domain/entities/security-levels/security-level.entity";
import { SecurityLevelRepository } from "@domain/repositories/security-levels/security-level.repository";
import { Observable } from "rxjs";

@Injectable()
export class SecurityLevelRepositoryImp extends SecurityLevelRepository {
  constructor(private securityLevelDataSource: SecurityLevelDataSource) {
    super();
  }

  override getSecurityLevels(): Observable<SecurityLevelEntity[]> {
    return this.securityLevelDataSource.getSecurityLevels();
  }
}
