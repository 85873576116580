import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { ConfigEntity } from "@domain/entities/processes/config.entity";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { EditConfigInterface } from "@public-interface/endpoints-bodies/edit-config.interface";
import { Observable } from "rxjs";

@Injectable()
export class EditConfigByIdUseCase
  implements
    UseCase<
      {
        body: EditConfigInterface;
        params: number;
      },
      ConfigEntity
    >
{
  constructor(private userRepository: UserRepository) {}

  execute(data: {
    body: EditConfigInterface;
    params: number;
  }): Observable<ConfigEntity> {
    return this.userRepository.editConfig(data);
  }
}
