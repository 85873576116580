import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain-repositories/user/user.repository";
import { IntegrationEntity } from "@domain/entities/app/integration.entity";

@Injectable()
export class GetTeamServicesUseCase
  implements UseCase<{ id: number }, IntegrationEntity[]>
{
  constructor(private userRepository: UserRepository) {}

  execute(params: { id: number }): Observable<IntegrationEntity[]> {
    return this.userRepository.getTeam(params).pipe(
      map((team) => {
        return team.app ? team.app.integrations : [];
      })
    );
  }
}
