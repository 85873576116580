import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { Observable } from "rxjs";

@Injectable()
export class VerifyPasswordUseCase
  implements UseCase<{ password: string }, { detail: string }>
{
  constructor(private userRepository: UserRepository) {}

  execute(body: { password: string }): Observable<{ detail: string }> {
    return this.userRepository.verifyPassword(body);
  }
}
