import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain-repositories/user/user.repository";
import { Observable } from "rxjs";

@Injectable()
export class SendVerificationCodeUseCase
  implements UseCase<{ code: string }, any>
{
  constructor(private userRepository: UserRepository) {}

  execute(body: { code: string }): Observable<{ detail: string }> {
    return this.userRepository.verifyCode(body);
  }
}
