<div
  class="w-full bg-extra-white sm:bg-background-color py-2.5 px-3 rounded-[4px]"
>
  <div class="flex place-items-center gap-2 justify-between">
    <p class="text-mini">{{ team }}</p>
    <div class="flex items-center gap-2">
      <custom-icon icon="time" class="text-grey" />
      <p class="text-small text-grey">
        {{
          process.status === processStatus.Open
            ? formatedTime
            : ("COMMONS.FINISHED" | translate)
        }}
      </p>
      <ng-container *ngIf="process.status === processStatus.Open && !noAction">
        <span>|</span>
        <button>
          <custom-icon
            icon="close_circle"
            [size]="24"
            class="text-secondary hover:text-secondary-hover"
            (click)="closeEventEmitter()"
          />
        </button>
      </ng-container>
    </div>
  </div>
  <div class="mt-1 mb-2">
    <h4 class="mb-2">{{ process.title }}</h4>
    <p class="text-small">
      {{ process.service.name }} - {{ process.service.config.name }}
    </p>
  </div>
  <div class="flex justify-between gap-2">
    <ng-container
      *ngIf="process.status === processStatus.Open; else closedProcess"
    >
      <div class="flex flex-grow gap-2 items-center">
        <custom-icon icon="shield" />
        <p class="text-small">
          {{ process.service.config.security_level.name }}
        </p>
      </div>
    </ng-container>
    <button *ngIf="!noAction">
      <custom-icon
        [icon]="showGuardiansResponse ? 'chevron_top' : 'chevron_bottom'"
        [size]="24"
        class="text-primary hover:text-primary-hover"
        (click)="hideShowGuardiansResponse()"
      />
    </button>
  </div>
  <div
    *ngIf="showGuardiansResponse"
    class="flex flex-col gap-2.5 pt-2.5 mt-2.5 border-t-[0.5px] border-t-grey"
  >
    <div
      *ngFor="let guardian of process.guardians"
      class="flex justify-between"
    >
      <p>
        {{ guardian.guardian.first_name }} {{ guardian.guardian.last_name }}
      </p>
      <b class="capitalize">{{ guardian.accepted }}</b>
    </div>
  </div>
</div>

<ng-template #closedProcess>
  <div
    class="flex flex-grow justify-center place-items-center gap-2 px-2 py-1 rounded-lg"
    [ngClass]="{
      'bg-error04 text-error-dark': process.status === processStatus.Denied,
      'bg-success-04 text-success-dark':
        process.status === processStatus.Approved,
      'bg-[#DDDFE4] text-black':
        process.status === processStatus.Cancelled || processStatus.Expired
    }"
  >
    <custom-icon [icon]="closedStateIcon" />
    <p class="text-mini capitalize">{{ process.status }}</p>
  </div>
</ng-template>
