import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { Observable, map } from "rxjs";

@Injectable()
export class GetUserEmailUseCase implements UseCase<null, string> {
  constructor(private userRepository: UserRepository) {}

  execute(): Observable<string> {
    return this.userRepository.getUserBasicInfo().pipe(
      map((userBasicInfo) => {
        return userBasicInfo.email;
      })
    );
  }
}
