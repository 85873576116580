import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { AuthInterface } from "@public-interface/forms/auth-interface";
import { SessionManagerService } from "@utils/session-manager/session-manager.service";
import { SessionRemoteDataSource } from "@datasource/session/source/session-remote-datasource";
import { SessionModel } from "@datasource/session/remote/dto/session.model";
import { SessionRepository } from "@domain-repositories/session/session.repository";

@Injectable()
export class SessionImpRepository extends SessionRepository {
  constructor(
    private sessionRemoteDataSource: SessionRemoteDataSource,
    private sessionManagerService: SessionManagerService
  ) {
    super();
  }

  override login(body: AuthInterface): Observable<SessionModel> {
    return this.sessionRemoteDataSource
      .login(body)
      .pipe(
        tap((session) => this.sessionManagerService.setSessionInfo(session))
      );
  }

  override logout(): Observable<{ detail: string }> {
    return this.sessionRemoteDataSource
      .logout()
      .pipe(tap((_) => this.sessionManagerService.clearSessionInfo()));
  }
}
