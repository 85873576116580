import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain-repositories/user/user.repository";
import { TeamEntity } from "@domain/entities/user/team.entity";

@Injectable()
export class GetTeamUseCase implements UseCase<{ id: number }, TeamEntity> {
  constructor(private userRepository: UserRepository) {}

  execute(params: { id: number }): Observable<TeamEntity> {
    return this.userRepository.getTeam(params);
  }
}
