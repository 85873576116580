import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { Observable } from "rxjs";

@Injectable()
export class IsConfigEditableUseCase
  implements UseCase<number, { detail: string; reason: string }>
{
  constructor(private userRepository: UserRepository) {}

  execute(params: number): Observable<{ detail: string; reason: string }> {
    return this.userRepository.verifyConfig({
      params: params,
      body: { operation: "edit" },
    });
  }
}
