import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { ProcessRepository } from "@domain/repositories/processes/process.repository";
import { CreateProcessInterface } from "@public-interface/endpoints-bodies/create-process.interface";
import { Observable } from "rxjs";

@Injectable()
export class CreateProcessUseCase
  implements UseCase<CreateProcessInterface, { detail: string }>
{
  constructor(private processRepository: ProcessRepository) {}

  execute(body: CreateProcessInterface): Observable<{ detail: string }> {
    return this.processRepository.createProcess(body);
  }
}
