import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { SessionRepository } from "@domain-repositories/session/session.repository";
import { Observable } from "rxjs";

@Injectable()
export class LogoutAndCleanSessionUseCase implements UseCase<null, any> {
  constructor(private sessionRepository: SessionRepository) {}

  execute(): Observable<{ detail: string }> {
    return this.sessionRepository.logout();
  }
}
