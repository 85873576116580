import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GuardianCardTypeEnum as CardType } from "./guardian-card-type.enum";
import { GuardianEntity } from "@domain/entities/guardians/guardian.entity";

@Component({
  selector: "guardian-card",
  templateUrl: "./guardian-card.component.html",
  styleUrls: ["./guardian-card.component.scss"],
})
export class GuardianCardComponent implements OnInit {
  get cardType(): typeof CardType {
    return CardType;
  }
  @Input() type: CardType = CardType.Invitation;
  @Input() guardian: GuardianEntity = {} as GuardianEntity;
  @Input() emailInvitation: string = "";
  @Input() error: boolean = false;
  @Output() btnEmitter: EventEmitter<void> = new EventEmitter();
  @Output() clickBody: EventEmitter<void> = new EventEmitter();
  guardianInitials: string = "";

  ngOnInit(): void {
    if (this.type === CardType.Guardian) {
      this.guardianInitials = this.guardian.first_name
        .charAt(0)
        .concat(this.guardian.last_name.charAt(0));
    }
  }

  closeBtnEmmiter(): void {
    this.btnEmitter.emit();
  }

  clickBodyEmit(): void {
    this.clickBody.emit();
  }
}
