import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { GuardianEntity } from "@domain/entities/guardians/guardian.entity";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { Observable, map } from "rxjs";

@Injectable()
export class GetTeamGuardiansUseCase
  implements UseCase<{ id: number }, GuardianEntity[]>
{
  constructor(private userRepository: UserRepository) {}

  execute(params: { id: number }): Observable<GuardianEntity[]> {
    return this.userRepository.getTeam(params).pipe(
      map((response) => {
        return response.guardian_pool;
      })
    );
  }
}
