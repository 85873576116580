import { Observable } from "rxjs";
import { RegisterInterface } from "@public-interface/forms/register-interface";
import { UserEntity } from "@entities/user/user.entity";
import { TeamEntity } from "@entities/user/team.entity";
import { ChangePassInterface } from "@public-interface/endpoints-bodies/change-pass-interface";
import { UserBasicInfoEntity } from "@domain/entities/user/user-basic-info.entity";
import { ConfigEntity } from "@domain/entities/processes/config.entity";
import { CreateServiceInterface } from "@public-interface/endpoints-bodies/create-service.interface";
import { IntegrationEntity } from "@domain/entities/app/integration.entity";
import { CreateConfigInterface } from "@public-interface/endpoints-bodies/create-config.interface";
import { GuardianAndTeamInterface } from "@public-interface/endpoints-bodies/is-guardian-removable.interface";
import { EditServiceInterface } from "@public-interface/endpoints-bodies/edit-service.interface";
import { EditConfigInterface } from "@public-interface/endpoints-bodies/edit-config.interface";

export abstract class UserRepository {
  abstract checkRegisterRequirements(body: {
    email: string;
    idNumber: string;
    guardianCode?: string;
  }): Observable<{ detail: string }>;

  abstract generatePassword(): Observable<{ detail: string }>;

  abstract register(body: RegisterInterface): Observable<{ phi: string }>;

  abstract verifyCode(body: { code: string }): Observable<{ detail: string }>;

  abstract me(): Observable<UserEntity>;

  abstract teamsList(): Observable<TeamEntity[]>;

  abstract getTeam(params: { id: number }): Observable<TeamEntity>;

  abstract resendVerifyCode(body: {
    email: string;
  }): Observable<{ detail: string }>;

  abstract changePassword(
    body: ChangePassInterface
  ): Observable<{ detail: string }>;

  abstract sendChangePasswordEmail(body: {
    email: string;
  }): Observable<{ detail: string; url: string }>;

  abstract verifyGuardians(body: {
    emails: string[];
  }): Observable<{ email: string; state: string }[]>;

  abstract getUserBasicInfo(): Observable<UserBasicInfoEntity>;

  abstract createTeam(body: {
    name: string;
    emails: string[];
  }): Observable<{ detail: string }>;

  abstract inviteGuardian(body: {
    team_id: number;
    emails: string[];
  }): Observable<{ detail: string }>;

  abstract listConfigurations(body: {
    team_id: number;
  }): Observable<ConfigEntity[]>;

  abstract createService(
    body: CreateServiceInterface
  ): Observable<IntegrationEntity>;

  abstract createConfig(body: CreateConfigInterface): Observable<ConfigEntity>;

  abstract verifyPassword(body: {
    password: string;
  }): Observable<{ detail: string }>;

  abstract getService(params: number): Observable<IntegrationEntity>;

  abstract verifyService(data: {
    body: { operation: string };
    params: number;
  }): Observable<{ detail: string }>;

  abstract editService(data: {
    body: EditServiceInterface;
    params: number;
  }): Observable<IntegrationEntity>;

  abstract isGuardianRemovable(
    body: GuardianAndTeamInterface
  ): Observable<{ detail: string }>;

  abstract removeGuardian(
    body: GuardianAndTeamInterface
  ): Observable<{ detail: string }>;

  abstract deleteAccount(): Observable<{ detail: string }>;

  abstract deleteLocalUserInfo(): Observable<boolean>;

  abstract deleteService(params: number): Observable<{ detail: string }>;

  abstract verifyConfig(data: {
    body: { operation: string };
    params: number;
  }): Observable<{ detail: string; reason: string }>;

  abstract deleteConfig(params: number): Observable<{ detail: string }>;

  abstract editConfig(data: {
    body: EditConfigInterface;
    params: number;
  }): Observable<ConfigEntity>;
}
