import { Observable } from "rxjs";
import { ProcessResponseModel } from "../remote/dto/process-response.model";
import { ProcessModel } from "../remote/dto/process.model";
import { CreateProcessInterface } from "@public-interface/endpoints-bodies/create-process.interface";

export abstract class ProcessRemoteDataSource {
  abstract getKeyOwnerOpenedProcesses(body: {
    team_id: number;
  }): Observable<ProcessResponseModel>;

  abstract getKeyOwnerClosedProcesses(body: {
    team_id: number;
  }): Observable<ProcessResponseModel>;

  abstract getGuardianOpenedProcesses(body: {
    team_id: number;
  }): Observable<{ procedures: ProcessModel[] }>;

  abstract createProcess(
    body: CreateProcessInterface
  ): Observable<{ detail: string }>;

  abstract cancelProcess(
    body: { password: string },
    param: number
  ): Observable<{ detail: string }>;
}
