import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { SessionManagerService } from "@utils/session-manager/session-manager.service";

export const authenticationGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  const sessionManager = inject(SessionManagerService);

  function cleanStorageAndNavigate(): Promise<boolean> {
    sessionManager.clearSessionInfo();
    return router.navigateByUrl("/login");
  }

  return (
    (sessionManager.getSessionInfo()
      ? sessionManager.getSessionInfo().expires_at
      : 0) -
      Date.now() >
      1 || cleanStorageAndNavigate()
  );
};
