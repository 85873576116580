import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TwoBtnDialogData } from "./two-btn-dialog-data.interface";

@Component({
  selector: "two-btn-dialog",
  templateUrl: "./two-btn-dialog.component.html",
  styleUrls: ["./two-btn-dialog.component.scss"],
})
export class TwoBtnDialogComponent {
  @Output() btn1Emitter: EventEmitter<void> = new EventEmitter();
  @Output() btn2Emitter: EventEmitter<void> = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<TwoBtnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TwoBtnDialogData
  ) {}

  btn1Click(): void {
    if (this.data.btn1Fn) {
      this.btn1Emitter.emit();
    } else {
      this.selectionCloseDialog(true);
    }
  }

  btn2Click(): void {
    if (this.data.btn2Fn) {
      this.btn2Emitter.emit();
    } else {
      this.selectionCloseDialog(false);
    }
  }

  neutralCloseDialog(): void {
    this.dialogRef.close();
  }

  selectionCloseDialog(bool: boolean): void {
    this.dialogRef.close(bool);
  }
}
