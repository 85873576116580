import { RegisterInterface } from "@public-interface/forms/register-interface";
import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { Observable } from "rxjs";
import { UserRepository } from "@domain-repositories/user/user.repository";

@Injectable()
export class RegisterUseCase implements UseCase<RegisterInterface, any> {
  constructor(private userRepository: UserRepository) {}

  execute(body: RegisterInterface): Observable<{ phi: string }> {
    return this.userRepository.register(body);
  }
}
