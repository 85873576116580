<div>
  <div class="flex mb-4">
    <h4 class="flex-grow">{{ data.title }}</h4>
    <button (click)="neutralCloseDialog()">
      <custom-icon icon="close" class="hover:text-grey" />
    </button>
  </div>
  <div [innerHTML]="data.text"></div>
  <div
    class="flex flex-col-reverse sm:flex-row sm:justify-center md:justify-end gap-4 mt-8"
  >
    <custom-button
      [type]="data.btn1Type"
      [text]="data.btn1Text"
      [isLoading]="data.isBtn1Loading ?? false"
      (clickEvent)="btn1Click()"
    />
    <custom-button
      [type]="data.btn2Type"
      [text]="data.btn2Text"
      [isLoading]="data.isBtn2Loading ?? false"
      (clickEvent)="btn2Click()"
    />
  </div>
</div>
