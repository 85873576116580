import { ProcessEntity } from "@entities/processes/process.entity";
import { CreateProcessInterface } from "@public-interface/endpoints-bodies/create-process.interface";
import { Observable } from "rxjs";

export abstract class ProcessRepository {
  abstract getKeyOwnerOpenedProcesses(body: {
    team_id: number;
  }): Observable<ProcessEntity[]>;

  abstract getKeyOwnerClosedProcesses(body: {
    team_id: number;
  }): Observable<ProcessEntity[]>;

  abstract getGuardianOpenedProcesses(body: {
    team_id: number;
  }): Observable<ProcessEntity[]>;

  abstract createProcess(
    body: CreateProcessInterface
  ): Observable<{ detail: string }>;

  abstract cancelProcess(
    body: { password: string },
    param: number
  ): Observable<{ detail: string }>;
}
