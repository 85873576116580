import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain-repositories/user/user.repository";

@Injectable()
export class CheckRegisterRequirementsUseCase
  implements
    UseCase<{ email: string; idNumber: string; guardianCode?: string }, any>
{
  constructor(private userRepository: UserRepository) {}

  execute(body: {
    email: string;
    idNumber: string;
    guardianCode?: string;
  }): Observable<{ detail: string }> {
    return this.userRepository.checkRegisterRequirements(body);
  }
}
