import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { Observable } from "rxjs";

@Injectable()
export class VerifyGuardiansUseCase
  implements UseCase<string[], { email: string; state: string }[]>
{
  constructor(private userRepository: UserRepository) {}

  execute(emails: string[]): Observable<{ email: string; state: string }[]> {
    return this.userRepository.verifyGuardians({ emails });
  }
}
