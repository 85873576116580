<div class="py-2.5 px-3 rounded-four bg-extra-white sm:bg-background-color">
  <div class="flex justify-between gap-2 place-items-start">
    <h4>{{ process.title }}</h4>
    <div class="flex gap-2 text-grey place-items-center pt-1.5">
      <custom-icon icon="time" />
      <p class="text-small whitespace-nowrap">{{ formattedTime }}</p>
    </div>
  </div>
  <p class="text-small">
    {{ process.service.name }} - {{ process.service.config.name }}
  </p>
</div>
