import { Component, Input, OnInit } from "@angular/core";
import { ProcessEntity } from "@domain/entities/processes/process.entity";
import { FormatTimeService } from "@utils/format-time/format-time.service";

@Component({
  selector: "process-guardian-card",
  templateUrl: "./process-guardian-card.component.html",
  styleUrls: ["./process-guardian-card.component.scss"],
})
export class ProcessGuardianCardComponent implements OnInit {
  @Input({ required: true }) process: ProcessEntity = {} as ProcessEntity;
  formattedTime: string = "";

  constructor(private formatTime: FormatTimeService) {}

  ngOnInit(): void {
    this.formattedTime = this.formatTime.expiresAtToFormattedTime(
      this.process.expires_at
    );
  }
}
