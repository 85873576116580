import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain-repositories/user/user.repository";

@Injectable()
export class SendChangePasswordEmailUseCase
  implements UseCase<string, { detail: string; url: string }>
{
  constructor(private userRepository: UserRepository) {}

  execute(email: string): Observable<{ detail: string; url: string }> {
    return this.userRepository.sendChangePasswordEmail({ email: email });
  }
}
