import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { Observable } from "rxjs";
import { UserRepository } from "@domain-repositories/user/user.repository";

@Injectable()
export class InviteGuardianUseCase
  implements UseCase<{ team_id: number; emails: string[] }, { detail: string }>
{
  constructor(private userRepository: UserRepository) {}

  execute(body: {
    team_id: number;
    emails: string[];
  }): Observable<{ detail: string }> {
    return this.userRepository.inviteGuardian(body);
  }
}
