import { Injectable } from "@angular/core";
import { SecurityLevelDataSource } from "../source/security-level.datasource";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { SecurityLevelModel } from "./dto/security-level.model";
import { Request } from "@core-interface/request";

@Injectable()
export class SecurityLevelDataSourceImp extends SecurityLevelDataSource {
  private urlPath = `${environment.baseUrl.DEV}/security-levels`;

  constructor(private db: Request) {
    super();
  }

  override getSecurityLevels(): Observable<SecurityLevelModel[]> {
    return this.db.doRequest("get", `${this.urlPath}`);
  }
}
