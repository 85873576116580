import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { TeamEntity } from "@entities/user/team.entity";
import { UserRepository } from "@domain-repositories/user/user.repository";
import { Observable } from "rxjs";

@Injectable()
export class GetUserTeamsListUseCase implements UseCase<null, TeamEntity[]> {
  constructor(private userRepository: UserRepository) {}
  execute(): Observable<TeamEntity[]> {
    return this.userRepository.teamsList();
  }
}
