import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { Observable } from "rxjs";

@Injectable()
export class DeleteServiceByIdUseCase
  implements UseCase<number, { detail: string }>
{
  constructor(private userRepository: UserRepository) {}

  execute(params: number): Observable<{ detail: string }> {
    return this.userRepository.deleteService(params);
  }
}
