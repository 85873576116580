import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component } from "@angular/core";
import { ToastService } from "@utils/toast-service/toast-service.service";
import { ToastState } from "@utils/toast-service/toast-type.enum";

@Component({
  selector: "toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
  animations: [
    trigger("toastTrigger", [
      state("open", style({ transform: "translateY(0%)" })),
      state("close", style({ transform: "translateY(-200%)" })),
      transition("open <=> close", [animate("300ms ease-in-out")]),
    ]),
  ],
})
export class ToastComponent {
  get toastState(): typeof ToastState {
    return ToastState;
  }
  message: string = "";
  state: ToastState = ToastState.SUCCESS;
  show: boolean = false;

  constructor(private toastService: ToastService) {
    this.toastService.toastMessage$.subscribe(
      (message) => (this.message = message)
    );

    this.toastService.toastState$.subscribe((state) => {
      this.state = state;
    });

    this.toastService.isToastShown$.subscribe((show) => {
      this.show = show;
    });
  }

  dismissToast(): void {
    this.toastService.dismissToast();
  }
}
