import { Mapper } from "@core-interface/mapper";
import { ProcessModel } from "@datasource/processes/remote/dto/process.model";
import { ProcessEntity } from "@entities/processes/process.entity";

export class ProcessImplementationRepositoryMapper extends Mapper<
  ProcessModel,
  ProcessEntity
> {
  mapTo(param: ProcessEntity): ProcessModel {
    return {
      id: param.id,
      status: param.status,
      title: param.title,
      created: param.created,
      expires_at: param.expires_at,
      time_left: param.time_left,
      delay: param.delay,
      is_acceptable: param.is_acceptable,
      service: param.service,
      guardians: param.guardians,
    };
  }
  mapFrom(param: ProcessModel): ProcessEntity {
    return {
      id: param.id,
      status: param.status,
      title: param.title,
      created: param.created,
      expires_at: param.expires_at,
      time_left: param.time_left,
      delay: param.delay,
      is_acceptable: param.is_acceptable,
      service: param.service,
      guardians: param.guardians,
    };
  }
}
