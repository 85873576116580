import { Injectable } from "@angular/core";
import { ProcessRemoteDataSource } from "../source/process-remote-datasource";
import { environment } from "@environments/environment";
import { Request } from "@core-interface/request";
import { Observable } from "rxjs";
import { ProcessResponseModel } from "./dto/process-response.model";
import { ProcessModel } from "./dto/process.model";
import { CreateProcessInterface } from "@public-interface/endpoints-bodies/create-process.interface";

@Injectable()
export class ProcessRemoteDataSourceImp extends ProcessRemoteDataSource {
  private urlPath = `${environment.baseUrl.DEV}/procedures`;

  constructor(private db: Request) {
    super();
  }

  override getKeyOwnerOpenedProcesses(body: {
    team_id: number;
  }): Observable<ProcessResponseModel> {
    return this.db.doRequest("post", `${this.urlPath}/key-owner/open`, body);
  }

  override getKeyOwnerClosedProcesses(body: {
    team_id: number;
  }): Observable<ProcessResponseModel> {
    return this.db.doRequest("post", `${this.urlPath}/key-owner/closed`, body);
  }

  override getGuardianOpenedProcesses(body: {
    team_id: number;
  }): Observable<{ procedures: ProcessModel[] }> {
    return this.db.doRequest("post", `${this.urlPath}/guardian/open`, body);
  }

  override createProcess(
    body: CreateProcessInterface
  ): Observable<{ detail: string }> {
    return this.db.doRequest("post", `${this.urlPath}/create`, body);
  }

  override cancelProcess(
    body: { password: string },
    param: number
  ): Observable<{ detail: string }> {
    return this.db.doRequest("post", `${this.urlPath}/${param}/cancel`, body);
  }
}
