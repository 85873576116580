import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "@environments/environment";
import { Request } from "@core-interface/request";
import { RegisterInterface } from "@public-interface/forms/register-interface";
import { UserRemoteDataSource } from "../source/user-remote-datasource";
import { UserModel } from "./dto/user.model";
import { TeamModel } from "./dto/team.model";
import { ChangePassInterface } from "@public-interface/endpoints-bodies/change-pass-interface";
import { ConfigModel } from "@data/datasource/processes/remote/dto/config.model";
import { CreateServiceInterface } from "@public-interface/endpoints-bodies/create-service.interface";
import { CreateConfigInterface } from "@public-interface/endpoints-bodies/create-config.interface";
import { IntegrationModel } from "@data/datasource/app/remote/dto/integration.model";
import { GuardianAndTeamInterface } from "@public-interface/endpoints-bodies/is-guardian-removable.interface";
import { EditServiceInterface } from "@public-interface/endpoints-bodies/edit-service.interface";
import { EditConfigInterface } from "@public-interface/endpoints-bodies/edit-config.interface";

@Injectable()
export class UserRemoteDataSourceImp extends UserRemoteDataSource {
  private urlPath = `${environment.baseUrl.DEV}/users`;

  constructor(private db: Request) {
    super();
  }

  override checkRegisterRequirements(body: {
    email: string;
    idNumber: string;
    guardianCode?: string;
  }): Observable<{ detail: string }> {
    return this.db.doRequest("post", `${this.urlPath}/register-requirements`, {
      email: body.email,
      personal_document_id: body.idNumber,
      code: body.guardianCode ? body.guardianCode : undefined,
    });
  }

  override generatePassword(): Observable<{ detail: string }> {
    return this.db.doRequest("get", `${this.urlPath}/generate_password`);
  }

  override register(body: RegisterInterface): Observable<{ phi: string }> {
    return this.db.doRequest("post", `${this.urlPath}/register`, body);
  }

  override verifyCode(body: { code: string }): Observable<{ detail: string }> {
    return this.db.doRequest("post", `${this.urlPath}/verify-code`, body);
  }

  override me(): Observable<UserModel> {
    return this.db.doRequest("get", `${this.urlPath}/me`);
  }

  override teamsList(): Observable<TeamModel[]> {
    return this.db.doRequest("get", `${this.urlPath}/list-teams`);
  }

  override getTeam(param: { id: number }): Observable<TeamModel> {
    return this.db.doRequest("get", `${this.urlPath}/${param.id}/get-team`);
  }

  override resendVerifyCode(body: {
    email: string;
  }): Observable<{ detail: string }> {
    return this.db.doRequest(
      "post",
      `${this.urlPath}/resend-verify-code`,
      body
    );
  }

  override changePassword(
    body: ChangePassInterface
  ): Observable<{ detail: string }> {
    return this.db.doRequest("patch", `${this.urlPath}/change-password`, body);
  }

  override sendChangePasswordEmail(body: {
    email: string;
  }): Observable<{ detail: string; url: string }> {
    return this.db.doRequest(
      "post",
      `${this.urlPath}/send-change-password-email`,
      body
    );
  }

  override verifyGuardians(body: {
    emails: string[];
  }): Observable<{ email: string; state: string }[]> {
    return this.db.doRequest("post", `${this.urlPath}/verify-guardians`, body);
  }

  override createTeam(body: {
    name: string;
    emails: string[];
  }): Observable<{ detail: string }> {
    return this.db.doRequest(
      "post",
      `${environment.baseUrl.DEV}/teams/create_team`,
      body
    );
  }

  override inviteGuardian(body: {
    team_id: number;
    emails: string[];
  }): Observable<{ detail: string }> {
    return this.db.doRequest("post", `${this.urlPath}/invite-guardian`, body);
  }

  override listConfigurations(body: {
    team_id: number;
  }): Observable<ConfigModel[]> {
    return this.db.doRequest(
      "post",
      `${this.urlPath}/list-configurations`,
      body
    );
  }

  override createService(
    body: CreateServiceInterface
  ): Observable<IntegrationModel> {
    return this.db.doRequest(
      "post",
      `${environment.baseUrl.DEV}/integrations/create`,
      body
    );
  }

  override createConfig(body: CreateConfigInterface): Observable<ConfigModel> {
    return this.db.doRequest(
      "post",
      `${environment.baseUrl.DEV}/configurations/create`,
      body
    );
  }

  override verifyPassword(body: {
    password: string;
  }): Observable<{ detail: string }> {
    return this.db.doRequest("post", `${this.urlPath}/verify-password`, body);
  }

  override getService(params: number): Observable<IntegrationModel> {
    return this.db.doRequest(
      "get",
      `${this.urlPath}/${params}/get-integration`
    );
  }

  override verifyService(data: {
    body: { operation: string };
    params: number;
  }): Observable<{ detail: string }> {
    return this.db.doRequest(
      "post",
      `${environment.baseUrl.DEV}/integrations/${data.params}/verify`,
      data.body
    );
  }

  override editService(data: {
    body: EditServiceInterface;
    params: number;
  }): Observable<IntegrationModel> {
    return this.db.doRequest(
      "post",
      `${environment.baseUrl.DEV}/integrations/${data.params}/edit`,
      data.body
    );
  }

  override isGuardianRemovable(
    body: GuardianAndTeamInterface
  ): Observable<{ detail: string }> {
    return this.db.doRequest(
      "post",
      `${this.urlPath}/verify-remove-guardian`,
      body
    );
  }

  override removeGuardian(
    body: GuardianAndTeamInterface
  ): Observable<{ detail: string }> {
    return this.db.doRequest("post", `${this.urlPath}/remove-guardian`, body);
  }

  override deleteAccount(): Observable<{ detail: string }> {
    return this.db.doRequest("delete", `${this.urlPath}/delete`);
  }

  override deleteService(params: number): Observable<{ detail: string }> {
    return this.db.doRequest(
      "delete",
      `${environment.baseUrl.DEV}/integrations/${params}/delete`
    );
  }

  override verifyConfig(data: {
    body: { operation: string };
    params: number;
  }): Observable<{ detail: string; reason: string }> {
    return this.db.doRequest(
      "post",
      `${environment.baseUrl.DEV}/configurations/${data.params}/verify`,
      data.body
    );
  }

  override deleteConfig(params: number): Observable<{ detail: string }> {
    return this.db.doRequest(
      "delete",
      `${environment.baseUrl.DEV}/configurations/${params}/delete`
    );
  }

  override editConfig(data: {
    body: EditConfigInterface;
    params: number;
  }): Observable<ConfigModel> {
    return this.db.doRequest(
      "post",
      `${environment.baseUrl.DEV}/configurations/${data.params}/edit`,
      data.body
    );
  }
}
