import { Component, Input } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "custom-icon",
  templateUrl: "./custom-icon.component.html",
  styleUrls: ["./custom-icon.component.scss"],
})
export class CustomIconComponent {
  @Input({ required: true }) icon: string = "";
  @Input() size: number = 20;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "close",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/close.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "warning",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/warning.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "visibility_off",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/visibility_off.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "visibility_on",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/visibility_on.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "time",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/time.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "people",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/people.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "shield",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/shield.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "close_circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/close_circle.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "more_square",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/more_square.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "cancel_square",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/cancel_square.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "check_circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/check_circle.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/edit.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/delete.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "check",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/check.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "reduce_circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/reduce_circle.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "expand_circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/expand_circle.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "exchange",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/exchange.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "mail",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/mail.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "phone",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/phone.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "id",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/id.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "chevron_bottom",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/chevron_bottom.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "chevron_top",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/chevron_top.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "info",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/info.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "error",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/error.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "camera",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/camera.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "arrow_left",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/arrow_left.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "exit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/exit.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "user",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/user.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "add",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/add.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "arrow_right",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/arrow_right.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "proccess",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/proccess.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "blocked",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/blocked.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "hide_image",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/hide_image.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "search",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/search.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "gear",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/gear.svg")
    );
  }
}
