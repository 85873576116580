import { SecurityLevelRepository } from "@domain/repositories/security-levels/security-level.repository";
import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { SecurityLevelEntity } from "@domain/entities/security-levels/security-level.entity";
import { Observable } from "rxjs";

@Injectable()
export class GetSecurityLevelsUseCase
  implements UseCase<null, SecurityLevelEntity[]>
{
  constructor(private securityLevelRepository: SecurityLevelRepository) {}

  execute(): Observable<SecurityLevelEntity[]> {
    return this.securityLevelRepository.getSecurityLevels();
  }
}
