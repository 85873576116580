import { UseCase } from "@core-interface/use-case";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProcessEntity } from "@entities/processes/process.entity";
import { ProcessRepository } from "@domain-repositories/processes/process.repository";

@Injectable()
export class GetGuardianOpenProcessesUseCase
  implements UseCase<{ team_id: number }, ProcessEntity[]>
{
  constructor(private processRepository: ProcessRepository) {}
  execute(body: { team_id: number }): Observable<ProcessEntity[]> {
    return this.processRepository.getGuardianOpenedProcesses(body);
  }
}
