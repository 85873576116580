<div class="flex mt-6 mb-4 place-items-center justify-between">
  <div class="flex gap-8 place-items-center">
    <a href="/home"><img src="assets/imgs/sigpa-black.svg" /></a>
    <a href="/profile" class="uppercase text-black hidden sm:block">{{
      "COMMONS.MY_PROFILE" | translate
    }}</a>
  </div>

  <div class="flex gap-6">
    <button (click)="navigateToProfileEmitter()">
      <custom-icon icon="user" [size]="24" class="sm:hidden" />
    </button>
    <button (click)="logoutEmitter()" class="sm:flex gap-2">
      <p class="hidden sm:block">{{ "COMMONS.EXIT" | translate }}</p>
      <custom-icon icon="exit" [size]="24" />
    </button>
  </div>
</div>
