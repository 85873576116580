export class Sources {
  public static DATA_URL = Object.freeze({
    DEV: "https://sigpa-staging.rudo.es/api/v1",
  });

  public static APP_DATA = Object.freeze({
    grant_type: "password",
    client_id: "id",
    client_secret: "secret",
  });

  public static publicEndpoints = [
    "/users/register-requirements",
    "/users/resend-verify-code",
    "/users/register",
    "/users/verify-code",
    "/users/login",
    "/users/generate_password",
  ];
}
