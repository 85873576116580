import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ToastState } from "./toast-type.enum";
import { ToastDataInterface } from "./toast-data.interface";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  isToastShown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  toastMessage$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  toastState$: BehaviorSubject<ToastState> = new BehaviorSubject<ToastState>(
    ToastState.SUCCESS
  );

  showToast(data: ToastDataInterface): void {
    this.toastState$.next(data.state);
    this.toastMessage$.next(data.message);
    this.isToastShown$.next(true);

    setTimeout(() => {
      this.dismissToast();
    }, 3000);
  }

  dismissToast(): void {
    this.isToastShown$.next(false);
  }
}
