import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { IntegrationEntity } from "@domain/entities/app/integration.entity";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { EditServiceInterface } from "@public-interface/endpoints-bodies/edit-service.interface";
import { Observable } from "rxjs";

@Injectable()
export class EditServiceByIdUseCase
  implements
    UseCase<
      {
        body: EditServiceInterface;
        params: number;
      },
      IntegrationEntity
    >
{
  constructor(private userRepository: UserRepository) {}

  execute(data: {
    body: EditServiceInterface;
    params: number;
  }): Observable<IntegrationEntity> {
    return this.userRepository.editService(data);
  }
}
