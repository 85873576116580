<div
  [ngClass]="{ 'border-warning': error }"
  class="flex items-center justify-between gap-2 py-2 px-4 border-grey bg-extra-white"
  [ngClass]="{
    'border-[1px] rounded-ten': type !== cardType.GuardianList,
    'cursor-pointer hover:bg-white': type === cardType.GuardianList
  }"
  (click)="clickBodyEmit()"
>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="cardType.Guardian">
      <div class="flex items-center gap-2">
        <user-profile-img
          [size]="40"
          [textSize]="18"
          [userName]="{
            firstName: guardian.first_name,
            lastName: guardian.last_name
          }"
        />
        <div class="flex flex-col gap-2">
          <b>{{ guardian.first_name }} {{ guardian.last_name }}</b>
          <p>{{ guardian.email }}</p>
        </div>
      </div>
      <button (click)="closeBtnEmmiter()">
        <custom-icon
          icon="close_circle"
          [size]="24"
          class="text-secondary hover:text-secondary-hover"
        />
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="cardType.GuardianList">
      <div class="flex items-center gap-2">
        <user-profile-img
          [size]="40"
          [textSize]="18"
          [userName]="{
            firstName: guardian.first_name,
            lastName: guardian.last_name
          }"
        />
        <div class="flex flex-col gap-2">
          <b>{{ guardian.first_name }} {{ guardian.last_name }}</b>
          <p>{{ guardian.email }}</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="cardType.Invitation">
      <b>{{ emailInvitation }}</b>
      <p class="text-small text-grey">
        {{ "GUARDIANS_PAGE.PENDING" | translate }}
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="cardType.InvitationBtn">
      <b>{{ emailInvitation }}</b>
      <button>
        <div class="flex flex-row gap-4">
          <custom-icon
            *ngIf="error"
            icon="warning"
            [size]="24"
            class="text-warning"
          />
          <custom-icon
            icon="close_circle"
            [size]="24"
            class="text-secondary hover:text-secondary-hover"
            (click)="closeBtnEmmiter()"
          />
        </div>
      </button>
    </ng-container>
  </ng-container>
</div>
