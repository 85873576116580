import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormatTimeService {
  expiresAtToFormattedTime(expiresAt: string): string {
    let msLeft = Date.parse(expiresAt) - Date.now();
    const days = Math.floor(msLeft / (24 * 60 * 60 * 1000));
    const daysms = msLeft % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = msLeft % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));

    const parts: string[] = [];
    if (days) parts.push(`${days}d`);
    if (hours) parts.push(`${hours}h`);
    if (minutes) parts.push(`${minutes}m`);
    return parts.join(" ");
  }

  durationToFormattedTime(hours: number, minutes: number) {
    const formatedTime: string[] = [];
    if (hours >= 24) {
      formatedTime.push(`${Math.round(hours / 24)}d`);
      if (hours % 24 > 0) {
        formatedTime.push(`${hours % 24}h`);
      }
    } else if (hours > 0) {
      formatedTime.push(`${hours}h`);
    }
    if (minutes > 0) {
      formatedTime.push(`${minutes}m`);
    }

    return formatedTime.join(" ");
  }
}
