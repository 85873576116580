import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UseCase } from "@core-interface/use-case";
import { AuthInterface } from "@public-interface/forms/auth-interface";
import { SessionRepository } from "@domain-repositories/session/session.repository";
import { SessionEntity } from "@entities/session/session.entity";

@Injectable()
export class LoginAndGetStatusUseCase
  implements UseCase<{ email: string; password: string }, any>
{
  constructor(private sessionRepository: SessionRepository) {}

  execute(body: AuthInterface): Observable<SessionEntity> {
    return this.sessionRepository.login(body);
  }
}
