import { Observable } from "rxjs";
import { RegisterInterface } from "@public-interface/forms/register-interface";
import { UserModel } from "../remote/dto/user.model";
import { TeamModel } from "../remote/dto/team.model";
import { ChangePassInterface } from "@public-interface/endpoints-bodies/change-pass-interface";
import { ConfigModel } from "@data/datasource/processes/remote/dto/config.model";
import { CreateServiceInterface } from "@public-interface/endpoints-bodies/create-service.interface";
import { CreateConfigInterface } from "@public-interface/endpoints-bodies/create-config.interface";
import { IntegrationModel } from "@data/datasource/app/remote/dto/integration.model";
import { GuardianAndTeamInterface } from "@public-interface/endpoints-bodies/is-guardian-removable.interface";
import { EditServiceInterface } from "@public-interface/endpoints-bodies/edit-service.interface";
import { EditConfigInterface } from "@public-interface/endpoints-bodies/edit-config.interface";

export abstract class UserRemoteDataSource {
  abstract checkRegisterRequirements(body: {
    email: string;
    idNumber: string;
    guardianCode?: string;
  }): Observable<{ detail: string }>;

  abstract generatePassword(): Observable<{ detail: string }>;

  abstract register(body: RegisterInterface): Observable<{ phi: string }>;

  abstract verifyCode(body: { code: string }): Observable<{ detail: string }>;

  abstract me(): Observable<UserModel>;

  abstract teamsList(): Observable<TeamModel[]>;

  abstract getTeam(param: { id: number }): Observable<TeamModel>;

  abstract resendVerifyCode(body: {
    email: string;
  }): Observable<{ detail: string }>;

  abstract changePassword(
    body: ChangePassInterface
  ): Observable<{ detail: string }>;

  abstract sendChangePasswordEmail(body: {
    email: string;
  }): Observable<{ detail: string; url: string }>;

  abstract verifyGuardians(body: {
    emails: string[];
  }): Observable<{ email: string; state: string }[]>;

  abstract createTeam(body: {
    name: string;
    emails: string[];
  }): Observable<{ detail: string }>;

  abstract inviteGuardian(body: {
    team_id: number;
    emails: string[];
  }): Observable<{ detail: string }>;

  abstract listConfigurations(body: {
    team_id: number;
  }): Observable<ConfigModel[]>;

  abstract createService(
    body: CreateServiceInterface
  ): Observable<IntegrationModel>;

  abstract createConfig(body: CreateConfigInterface): Observable<ConfigModel>;

  abstract verifyPassword(body: {
    password: string;
  }): Observable<{ detail: string }>;

  abstract getService(params: number): Observable<IntegrationModel>;

  abstract verifyService(data: {
    body: { operation: string };
    params: number;
  }): Observable<{ detail: string }>;

  abstract editService(data: {
    body: EditServiceInterface;
    params: number;
  }): Observable<IntegrationModel>;

  abstract isGuardianRemovable(
    body: GuardianAndTeamInterface
  ): Observable<{ detail: string }>;

  abstract removeGuardian(
    body: GuardianAndTeamInterface
  ): Observable<{ detail: string }>;

  abstract deleteAccount(): Observable<{ detail: string }>;

  abstract deleteService(params: number): Observable<{ detail: string }>;

  abstract verifyConfig(data: {
    body: { operation: string };
    params: number;
  }): Observable<{ detail: string; reason: string }>;

  abstract deleteConfig(params: number): Observable<{ detail: string }>;

  abstract editConfig(data: {
    body: EditConfigInterface;
    params: number;
  }): Observable<ConfigModel>;
}
