import { Injectable } from "@angular/core";
import { Observable, map, of, tap } from "rxjs";
import { RegisterInterface } from "@public-interface/forms/register-interface";
import { UserImplementationRepositoryMapper } from "./mappers/user.mapper";
import { UserRemoteDataSource } from "@datasource/user/source/user-remote-datasource";
import { UserModel } from "@datasource/user/remote/dto/user.model";
import { UserRepository } from "@domain-repositories/user/user.repository";
import { TeamEntity } from "@entities/user/team.entity";
import { UserEntity } from "@entities/user/user.entity";
import { ChangePassInterface } from "@public-interface/endpoints-bodies/change-pass-interface";
import { UserLocalDataSource } from "@data/datasource/user/source/user-local-datasource";
import { UserBasicInfoEntity } from "@domain/entities/user/user-basic-info.entity";
import { UserBasicInfoModel } from "@data/datasource/user/local/dbo/user-info.model";
import { ConfigEntity } from "@domain/entities/processes/config.entity";
import { IntegrationEntity } from "@domain/entities/app/integration.entity";
import { CreateServiceInterface } from "@public-interface/endpoints-bodies/create-service.interface";
import { CreateConfigInterface } from "@public-interface/endpoints-bodies/create-config.interface";
import { GuardianAndTeamInterface } from "@public-interface/endpoints-bodies/is-guardian-removable.interface";
import { EditServiceInterface } from "@public-interface/endpoints-bodies/edit-service.interface";
import { EditConfigInterface } from "@public-interface/endpoints-bodies/edit-config.interface";

@Injectable()
export class UserImpRepository extends UserRepository {
  constructor(
    private userRemoteDataSource: UserRemoteDataSource,
    private userMappers: UserImplementationRepositoryMapper,
    private userLocalDataSource: UserLocalDataSource
  ) {
    super();
  }

  override checkRegisterRequirements(body: {
    email: string;
    idNumber: string;
    guardianCode?: string | undefined;
  }): Observable<{ detail: string }> {
    return this.userRemoteDataSource.checkRegisterRequirements(body);
  }

  override generatePassword(): Observable<{ detail: string }> {
    return this.userRemoteDataSource.generatePassword();
  }

  override register(body: RegisterInterface): Observable<{ phi: string }> {
    return this.userRemoteDataSource.register(body);
  }

  override verifyCode(body: { code: string }): Observable<{ detail: string }> {
    return this.userRemoteDataSource.verifyCode(body);
  }

  override me(): Observable<UserEntity> {
    return this.userRemoteDataSource.me().pipe(
      map((response: UserModel) => {
        return this.userMappers.mapFrom(response);
      }),
      tap((userInfo) => {
        this.userLocalDataSource.setUserInfo(userInfo.email, userInfo.role);
      })
    );
  }

  override teamsList(): Observable<TeamEntity[]> {
    return this.userRemoteDataSource.teamsList();
  }

  override getTeam(params: { id: number }): Observable<TeamEntity> {
    return this.userRemoteDataSource.getTeam(params);
  }

  override resendVerifyCode(body: {
    email: string;
  }): Observable<{ detail: string }> {
    return this.userRemoteDataSource.resendVerifyCode(body);
  }

  override changePassword(
    body: ChangePassInterface
  ): Observable<{ detail: string }> {
    return this.userRemoteDataSource.changePassword(body);
  }

  override sendChangePasswordEmail(body: {
    email: string;
  }): Observable<{ detail: string; url: string }> {
    return this.userRemoteDataSource.sendChangePasswordEmail(body);
  }

  override verifyGuardians(body: {
    emails: string[];
  }): Observable<{ email: string; state: string }[]> {
    return this.userRemoteDataSource.verifyGuardians(body);
  }

  override getUserBasicInfo(): Observable<UserBasicInfoEntity> {
    let userInfo: UserBasicInfoModel | null =
      this.userLocalDataSource.getUserInfo();

    if (userInfo === null || Date.now() - userInfo.set_date > 1800000) {
      return this.me().pipe(
        map((user) => {
          return (userInfo = {
            role: user.role,
            email: user.email,
            set_date: Date.now(),
          });
        })
      );
    }

    return of(userInfo);
  }

  override createTeam(body: {
    name: string;
    emails: string[];
  }): Observable<{ detail: string }> {
    return this.userRemoteDataSource.createTeam(body);
  }

  override inviteGuardian(body: {
    team_id: number;
    emails: string[];
  }): Observable<{ detail: string }> {
    return this.userRemoteDataSource.inviteGuardian(body);
  }

  override listConfigurations(body: {
    team_id: number;
  }): Observable<ConfigEntity[]> {
    return this.userRemoteDataSource.listConfigurations(body);
  }

  override createService(
    body: CreateServiceInterface
  ): Observable<IntegrationEntity> {
    return this.userRemoteDataSource.createService(body);
  }

  override createConfig(body: CreateConfigInterface): Observable<ConfigEntity> {
    return this.userRemoteDataSource.createConfig(body);
  }

  override verifyPassword(body: {
    password: string;
  }): Observable<{ detail: string }> {
    return this.userRemoteDataSource.verifyPassword(body);
  }

  override getService(params: number): Observable<IntegrationEntity> {
    return this.userRemoteDataSource.getService(params);
  }

  override verifyService(data: {
    body: { operation: string };
    params: number;
  }): Observable<{ detail: string }> {
    return this.userRemoteDataSource.verifyService(data);
  }

  override editService(data: {
    body: EditServiceInterface;
    params: number;
  }): Observable<IntegrationEntity> {
    return this.userRemoteDataSource.editService(data);
  }

  override isGuardianRemovable(
    body: GuardianAndTeamInterface
  ): Observable<{ detail: string }> {
    return this.userRemoteDataSource.isGuardianRemovable(body);
  }

  override removeGuardian(
    body: GuardianAndTeamInterface
  ): Observable<{ detail: string }> {
    return this.userRemoteDataSource.removeGuardian(body);
  }

  override deleteAccount(): Observable<{ detail: string }> {
    return this.userRemoteDataSource.deleteAccount();
  }

  override deleteLocalUserInfo(): Observable<boolean> {
    return of(this.userLocalDataSource.clearUserInfo());
  }

  override deleteService(params: number): Observable<{ detail: string }> {
    return this.userRemoteDataSource.deleteService(params);
  }

  override verifyConfig(data: {
    body: { operation: string };
    params: number;
  }): Observable<{ detail: string; reason: string }> {
    return this.userRemoteDataSource.verifyConfig(data);
  }

  override deleteConfig(params: number): Observable<{ detail: string }> {
    return this.userRemoteDataSource.deleteConfig(params);
  }

  override editConfig(data: {
    body: EditConfigInterface;
    params: number;
  }): Observable<ConfigEntity> {
    return this.userRemoteDataSource.editConfig(data);
  }
}
