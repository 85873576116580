import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UseCase } from "@core-interface/use-case";
import { UserRepository } from "@domain-repositories/user/user.repository";

@Injectable()
export class GeneratePasswordUseCase implements UseCase<null, any> {
  constructor(private userRepository: UserRepository) {}

  execute(): Observable<{ detail: string }> {
    return this.userRepository.generatePassword();
  }
}
