import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "custom-navbar",
  templateUrl: "./custom-navbar.component.html",
  styleUrls: ["./custom-navbar.component.scss"],
})
export class CustomNavbarComponent {
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateToProfile: EventEmitter<void> = new EventEmitter<void>();

  logoutEmitter(): void {
    this.logout.emit();
  }

  navigateToProfileEmitter(): void {
    this.navigateToProfile.emit();
  }
}
