import { Injectable } from "@angular/core";
import { SessionInterface } from "./session.interface";
import { SessionModel } from "@data/datasource/session/remote/dto/session.model";

@Injectable({
  providedIn: "root",
})
export class SessionManagerService {
  getSessionInfo(): SessionInterface {
    return JSON.parse(localStorage.getItem("sigpa.session")!);
  }
  setSessionInfo(sessionData: SessionModel): void {
    return localStorage.setItem(
      "sigpa.session",
      JSON.stringify({
        token_type: sessionData.token_type,
        access_token: sessionData.access_token,
        refresh_token: sessionData.refresh_token,
        expires_at: Date.now() + sessionData.expires_in * 1000,
      } as SessionInterface)
    );
  }
  clearSessionInfo(): void {
    return localStorage.removeItem("sigpa.session");
  }
}
