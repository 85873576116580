import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { Request } from "@core-interface/request";
import { SessionImpRepository } from "./repositories/session/session-implementation.repository";
import { SessionRemoteDataSourceImp } from "./datasource/session/remote/session-remote-datasource-imp";
import { UserImpRepository } from "./repositories/user/user-implementation.repository";
import { SessionRemoteDataSource } from "./datasource/session/source/session-remote-datasource";
import { UserRemoteDataSourceImp } from "./datasource/user/remote/user-remote-datasource-imp";
import { UserRemoteDataSource } from "./datasource/user/source/user-remote-datasource";
import { UserImplementationRepositoryMapper } from "./repositories/user/mappers/user.mapper";
import { ProcessRemoteDataSourceImp } from "./datasource/processes/remote/process-remote-datasource-imp";
import { ProcessRemoteDataSource } from "./datasource/processes/source/process-remote-datasource";
import { ProcessImpRepository } from "./repositories/processes/process-implementation.repository";
import { ProcessImplementationRepositoryMapper } from "./repositories/processes/mappers/process.mapper";
import { LoginAndGetStatusUseCase } from "@usecases/session/login-and-get-status.usecase";
import { SessionRepository } from "@domain-repositories/session/session.repository";
import { CheckRegisterRequirementsUseCase } from "@usecases/user/check-register-requirements.usecase";
import { UserRepository } from "@domain-repositories/user/user.repository";
import { GeneratePasswordUseCase } from "@usecases/user/generate-password.usecase";
import { RegisterUseCase } from "@usecases/user/register.usecase";
import { SendVerificationCodeUseCase } from "@usecases/user/send-verification-code.usecase";
import { GetUserInformationUseCase } from "@usecases/user/get-user-information.usecase";
import { LogoutAndCleanSessionUseCase } from "@usecases/session/logout-and-clean-session.usecase";
import { ProcessRepository } from "@domain-repositories/processes/process.repository";
import { GetUserTeamsListUseCase } from "@usecases/user/get-user-teams-list.usecase";
import { GetTeamOpenProcessesUseCase } from "@usecases/processes/get-team-open-processes.usecase";
import { GetTeamUseCase } from "@domain/usecases/user/get-team.usecase";
import { GetTeamClosedProcessesUseCase } from "@domain/usecases/processes/get-team-closed-processes.usecase";
import { GetTeamGuardiansAndInvitationsUseCase } from "@domain/usecases/guardians/get-team-guardians-and-invitations.usecase";
import { GetTeamServicesUseCase } from "@domain/usecases/processes/get-team-services.usecase";
import { GetUserFirstTeamUseCase } from "@domain/usecases/user/get-user-first-team.usecase";
import { GetGuardianOpenProcessesUseCase } from "@domain/usecases/processes/get-guardian-open-processes.usecase";
import { ResendVerifyCodeUseCase } from "@domain/usecases/user/resend-verify-code.usecase";
import { ChangePasswordUseCase } from "@domain/usecases/user/changePassword.usecase";
import { SendChangePasswordEmailUseCase } from "@domain/usecases/user/sendChangePasswordEmail.usecase";
import { VerifyGuardiansUseCase } from "@domain/usecases/user/verify-guardians.usecase";
import { UserLocalDataSource } from "./datasource/user/source/user-local-datasource";
import { UserLocalDataSourceImp } from "./datasource/user/local/user-local-datasource-imp";
import { GetUserEmailUseCase } from "@domain/usecases/user/get-user-email.usecase";
import { CreateTeamUseCase } from "@domain/usecases/user/create-team.usecase";
import { InviteGuardianUseCase } from "@domain/usecases/user/invite-guardian.usecase";
import { CreateServiceUseCase } from "@domain/usecases/user/create-service.usecase";
import { GetSecurityLevelsUseCase } from "@domain/usecases/security-levels/get-security-levels.usecase";
import { SecurityLevelDataSource } from "./datasource/security-levels/source/security-level.datasource";
import { SecurityLevelDataSourceImp } from "./datasource/security-levels/remote/security-level.datasource-imp";
import { SecurityLevelRepository } from "@domain/repositories/security-levels/security-level.repository";
import { SecurityLevelRepositoryImp } from "./repositories/security-levels/security-level.repository-imp";
import { GetTeamGuardiansUseCase } from "@domain/usecases/guardians/get-team-guardians.usecase";
import { CreateConfigUseCase } from "@domain/usecases/user/create-config.usecase";
import { VerifyPasswordUseCase } from "@domain/usecases/user/verify-password.usecase";
import { CreateProcessUseCase } from "@domain/usecases/processes/create-process.usecase";
import { GetServiceByIdUseCase } from "@domain/usecases/user/get-service-by-id.usecase";
import { IsServiceEditableUseCase } from "@domain/usecases/user/is-service-editable.usecase";
import { EditServiceByIdUseCase } from "@domain/usecases/user/edit-service-by-id.usecase";
import { IsGuardianRemovableUseCase } from "@domain/usecases/user/is-guardian-removable.usecase";
import { RemoveGuardianByIdUseCase } from "@domain/usecases/user/remove-guardian-by-id.usecase";
import { DeleteAccountUseCase } from "@domain/usecases/user/delete-account.usecase";
import { DeleteLocalUserInfoUseCase } from "@domain/usecases/user/delete-local-user-info.usecase";
import { DeleteServiceByIdUseCase } from "@domain/usecases/user/delete-service-by-id.usecase";
import { IsServiceDeletableUseCase } from "@domain/usecases/user/is-service-deletable.usecase";
import { IsConfigDeletableUseCase } from "@domain/usecases/user/is-config-deletable.usecase";
import { DeleteConfigByIdUseCase } from "@domain/usecases/user/delete-config-by-id.usecase";
import { GetTeamConfigurationsUseCase } from "@domain/usecases/user/get-team-configurations.usecase";
import { EditConfigByIdUseCase } from "@domain/usecases/user/edit-config-by-id.usecase";
import { IsConfigEditableUseCase } from "@domain/usecases/user/is-config-editable.usecase";

@NgModule({
  providers: [
    Request,
    LoginAndGetStatusUseCase,
    { provide: SessionRepository, useClass: SessionImpRepository },
    { provide: SessionRemoteDataSource, useClass: SessionRemoteDataSourceImp },
    CheckRegisterRequirementsUseCase,
    { provide: UserRepository, useClass: UserImpRepository },
    { provide: UserRemoteDataSource, useClass: UserRemoteDataSourceImp },
    GeneratePasswordUseCase,
    RegisterUseCase,
    SendVerificationCodeUseCase,
    GetUserInformationUseCase,
    {
      provide: UserImplementationRepositoryMapper,
      useClass: UserImplementationRepositoryMapper,
    },
    LogoutAndCleanSessionUseCase,
    { provide: ProcessRemoteDataSource, useClass: ProcessRemoteDataSourceImp },
    { provide: ProcessRepository, useClass: ProcessImpRepository },
    GetUserTeamsListUseCase,
    GetTeamOpenProcessesUseCase,
    {
      provide: ProcessImplementationRepositoryMapper,
      useClass: ProcessImplementationRepositoryMapper,
    },
    GetTeamUseCase,
    GetTeamClosedProcessesUseCase,
    GetTeamGuardiansAndInvitationsUseCase,
    GetTeamServicesUseCase,
    GetUserFirstTeamUseCase,
    GetGuardianOpenProcessesUseCase,
    ResendVerifyCodeUseCase,
    ChangePasswordUseCase,
    SendChangePasswordEmailUseCase,
    VerifyGuardiansUseCase,
    { provide: UserLocalDataSource, useClass: UserLocalDataSourceImp },
    GetUserEmailUseCase,
    CreateTeamUseCase,
    InviteGuardianUseCase,
    CreateServiceUseCase,
    { provide: SecurityLevelDataSource, useClass: SecurityLevelDataSourceImp },
    { provide: SecurityLevelRepository, useClass: SecurityLevelRepositoryImp },
    GetSecurityLevelsUseCase,
    GetTeamGuardiansUseCase,
    CreateConfigUseCase,
    VerifyPasswordUseCase,
    CreateProcessUseCase,
    GetServiceByIdUseCase,
    IsServiceEditableUseCase,
    EditServiceByIdUseCase,
    IsGuardianRemovableUseCase,
    RemoveGuardianByIdUseCase,
    DeleteAccountUseCase,
    DeleteLocalUserInfoUseCase,
    DeleteServiceByIdUseCase,
    IsServiceDeletableUseCase,
    IsConfigDeletableUseCase,
    DeleteConfigByIdUseCase,
    GetTeamConfigurationsUseCase,
    IsConfigEditableUseCase,
    EditConfigByIdUseCase,
  ],
  imports: [CommonModule, HttpClientModule],
})
export class DataModule {}
