<div
  [@toastTrigger]="show ? 'open' : 'close'"
  class="toast z-50 flex justify-between gap-2 py-3 px-4 m-8 min-w-36 rounded-[100px] drop-shadow-[0_1px_6px_0_rgba(40,42,54,0.13)] backdrop-blur-[6px] absolute top-0 right-0"
  [ngClass]="{ 'bg-success-04': state === toastState.SUCCESS }"
>
  <div class="flex gap-2">
    <custom-icon
      [icon]="state === toastState.SUCCESS ? 'check_circle' : ''"
      [ngClass]="{ 'text-success-dark': state === toastState.SUCCESS }"
    />
    <p>{{ message }}</p>
  </div>
  <button (click)="dismissToast()"><custom-icon icon="close" /></button>
</div>
