<div
  class="flex gap-2 py-2.5 px-3 bg-background-color drop-shadow-[0_1px_6px_rgba(40,42,54,0.13)] backdrop-blur-[6px] rounded-[4px]"
>
  <div *ngIf="type === cardType.RADIO_BTN" class="pt-0.5">
    <input
      type="radio"
      [value]="checked"
      (change)="idEmit()"
      [checked]="checked"
      class="w-4 h-4"
    />
  </div>
  <div class="flex-grow">
    <div class="flex justify-between mb-1.5">
      <b>{{ config.name }}</b>
      <div *ngIf="type === cardType.ACTIONS" class="flex gap-4">
        <button (click)="editClick()">
          <custom-icon
            icon="edit"
            class="text-primary hover:text-primary-hover"
          />
        </button>
        <button (click)="deleteClick()">
          <custom-icon icon="delete" class="text-tertiary hover:text-grey" />
        </button>
      </div>
      <button *ngIf="type === cardType.LIST_ITEM" (click)="closeClick()">
        <custom-icon
          icon="close_circle"
          class="text-secondary hover:text-secondary-hover"
        />
      </button>
    </div>
    <div
      class="*:text-small *:text-grey [&>span]:flex [&>span>p]:w-40 flex flex-col gap-1.5"
    >
      <span>
        <p>{{ "CARDS.SERVICE.PROCESS_TIME" | translate }}:</p>
        <b>{{ formatedTime }}</b>
      </span>
      <span>
        <p>{{ "CARDS.SERVICE.SECURITY_LVL" | translate }}:</p>
        <b>{{ config.security_level.name }}</b>
      </span>
      <span>
        <p>{{ "CARDS.SERVICE.GUARDIANS" | translate }}:</p>
        <b>{{ config.guardians.length }}</b>
      </span>
    </div>
  </div>
</div>
