import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ProcessImplementationRepositoryMapper } from "./mappers/process.mapper";
import { ProcessRemoteDataSource } from "@datasource/processes/source/process-remote-datasource";
import { ProcessResponseModel } from "@datasource/processes/remote/dto/process-response.model";
import { ProcessEntity } from "@entities/processes/process.entity";
import { ProcessRepository } from "@domain-repositories/processes/process.repository";
import { ProcessModel } from "@data/datasource/processes/remote/dto/process.model";
import { CreateProcessInterface } from "@public-interface/endpoints-bodies/create-process.interface";

@Injectable()
export class ProcessImpRepository extends ProcessRepository {
  constructor(
    private processRemoteDataSource: ProcessRemoteDataSource,
    private processMappers: ProcessImplementationRepositoryMapper
  ) {
    super();
  }

  override getKeyOwnerOpenedProcesses(body: {
    team_id: number;
  }): Observable<ProcessEntity[]> {
    return this.processRemoteDataSource.getKeyOwnerOpenedProcesses(body).pipe(
      map((response: ProcessResponseModel) => {
        let processes: ProcessEntity[] = [];
        response.procedures.forEach((element) =>
          processes.push(this.processMappers.mapFrom(element))
        );
        return processes;
      })
    );
  }

  override getKeyOwnerClosedProcesses(body: {
    team_id: number;
  }): Observable<ProcessEntity[]> {
    return this.processRemoteDataSource.getKeyOwnerClosedProcesses(body).pipe(
      map((response: ProcessResponseModel) => {
        let processes: ProcessEntity[] = [];
        response.procedures.forEach((element) =>
          processes.push(this.processMappers.mapFrom(element))
        );
        return processes;
      })
    );
  }

  override getGuardianOpenedProcesses(body: {
    team_id: number;
  }): Observable<ProcessEntity[]> {
    return this.processRemoteDataSource.getGuardianOpenedProcesses(body).pipe(
      map((response: { procedures: ProcessModel[] }) => {
        return response.procedures;
      })
    );
  }

  override createProcess(
    body: CreateProcessInterface
  ): Observable<{ detail: string }> {
    return this.processRemoteDataSource.createProcess(body);
  }

  override cancelProcess(
    body: { password: string },
    param: number
  ): Observable<{ detail: string }> {
    return this.processRemoteDataSource.cancelProcess(body, param);
  }
}
