import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProcessEntity } from "@entities/processes/process.entity";
import { ProcessStatus } from "@public-interface/process-status.enum";
import { FormatTimeService } from "@utils/format-time/format-time.service";

@Component({
  selector: "process-card",
  templateUrl: "./process-card.component.html",
  styleUrls: ["./process-card.component.scss"],
})
export class ProcessCardComponent {
  get processStatus(): typeof ProcessStatus {
    return ProcessStatus;
  }
  @Input({ required: true }) team: string = "";
  @Input({ required: true }) process: ProcessEntity = {} as ProcessEntity;
  @Input() noAction: boolean = false;
  @Output() closeEmitter: EventEmitter<void> = new EventEmitter();
  formatedTime: string = "";
  showGuardiansResponse: boolean = false;
  closedStateIcon: string = "";

  constructor(private formatTime: FormatTimeService) {}

  ngOnInit(): void {
    this.formatedTime = this.formatTime.expiresAtToFormattedTime(
      this.process.expires_at
    );

    if (this.process.status !== ProcessStatus.Open) {
      this.closedStateIcon = this.setClosedStateIcon();
    }
  }

  hideShowGuardiansResponse(): void {
    this.showGuardiansResponse = !this.showGuardiansResponse;
  }

  setClosedStateIcon(): string {
    switch (this.process.status) {
      case ProcessStatus.Denied: {
        return "close";
      }
      case ProcessStatus.Approved: {
        return "check";
      }
      default: {
        return "blocked";
      }
    }
  }

  closeEventEmitter(): void {
    this.closeEmitter.emit();
  }
}
