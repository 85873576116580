import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { CustomIconComponent } from "./custom-icon/custom-icon.component";
import { CustomFooterComponent } from "./custom-footer/custom-footer.component";
import { TranslateModule } from "@ngx-translate/core";
import { ReturnBackComponent } from "./return-back/return-back.component";
import { OneBtnDialogComponent } from "./one-btn-dialog/one-btn-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { CustomNavbarComponent } from "./custom-navbar/custom-navbar.component";
import { ProcessCardComponent } from "./process-card/process-card.component";
import { CustomButtonComponent } from "./custom-button/custom-button.component";
import { GuardianCardComponent } from "./guardian-card/guardian-card.component";
import { ConfigCardComponent } from "./config-card/config-card.component";
import { ProcessGuardianCardComponent } from "./process-guardian-card/process-guardian-card.component";
import { UserProfileImgComponent } from "./user-profile-img/user-profile-img.component";
import { ToastComponent } from "./toast/toast.component";
import { InfoLoadingDialogComponent } from "./info-loading-dialog/info-loading-dialog.component";
import { TwoBtnDialogComponent } from "./two-btn-dialog/two-btn-dialog.component";

@NgModule({
  declarations: [
    CustomIconComponent,
    CustomFooterComponent,
    ReturnBackComponent,
    OneBtnDialogComponent,
    CustomNavbarComponent,
    CustomButtonComponent,
    ProcessCardComponent,
    GuardianCardComponent,
    ConfigCardComponent,
    ProcessGuardianCardComponent,
    UserProfileImgComponent,
    ToastComponent,
    InfoLoadingDialogComponent,
    TwoBtnDialogComponent,
  ],
  imports: [CommonModule, MatIconModule, TranslateModule, MatDialogModule],
  exports: [
    CustomIconComponent,
    CustomFooterComponent,
    ReturnBackComponent,
    OneBtnDialogComponent,
    CustomNavbarComponent,
    CustomButtonComponent,
    ProcessCardComponent,
    GuardianCardComponent,
    ConfigCardComponent,
    ProcessGuardianCardComponent,
    UserProfileImgComponent,
    ToastComponent,
    InfoLoadingDialogComponent,
    TwoBtnDialogComponent,
  ],
  providers: [MatIconRegistry],
})
export class SharedComponentsModule {}
