import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { authenticationGuard } from "@guards/authentication.guard";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
    pathMatch: "full",
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/register/register.module").then((m) => m.RegisterModule),
    pathMatch: "full",
  },
  {
    path: "register/verify-account",
    loadChildren: () =>
      import("./pages/verify-account/verify-account.module").then(
        (m) => m.VerifyAccountModule
      ),
    pathMatch: "full",
  },
  {
    path: "error",
    loadChildren: () =>
      import("./pages/general-error/general-error.module").then(
        (m) => m.GeneralErrorModule
      ),
  },
  {
    path: "code-sent",
    loadChildren: () =>
      import("./pages/code-sent/code-sent.module").then(
        (m) => m.CodeSentModule
      ),
  },
  {
    path: "password-recovery",
    loadChildren: () =>
      import("./pages/pass-recovery/pass-recovery.module").then(
        (m) => m.PassRecoveryModule
      ),
  },
  {
    path: "update-password",
    loadChildren: () =>
      import("./pages/update-password/update-password.module").then(
        (m) => m.UpdatePasswordModule
      ),
  },
  {
    path: "deleted-account",
    loadChildren: () =>
      import("./pages/deleted-account/deleted-account.module").then(
        (m) => m.DeletedAccountModule
      ),
  },
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "",
    loadChildren: () =>
      import(
        "./containers/user-logged-in/user-logged-in-container.module"
      ).then((m) => m.UserLoggedInContainerModule),
    canActivate: [authenticationGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import(
        "./containers/public-only-text/public-only-text-container.module"
      ).then((m) => m.PublicOnlyTextContainerModule),
  },
  { path: "**", redirectTo: "empty", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
