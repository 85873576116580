import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Request } from "@core-interface/request";
import { environment } from "@environments/environment";
import { AuthInterface } from "@public-interface/forms/auth-interface";
import { SessionRemoteDataSource } from "../source/session-remote-datasource";
import { SessionModel } from "./dto/session.model";

@Injectable()
export class SessionRemoteDataSourceImp extends SessionRemoteDataSource {
  private urlPath = `${environment.baseUrl.DEV}/users`;

  constructor(private db: Request) {
    super();
  }

  override login(body: AuthInterface): Observable<SessionModel> {
    return this.db.doRequest("post", `${this.urlPath}/login`, {
      auth_flow: "PASSWORD",
      email: body.email,
      password: body.password,
    });
  }

  override logout(): Observable<{ detail: string }> {
    return this.db.doRequest("post", `${this.urlPath}/logout`);
  }
}
