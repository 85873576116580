import { Injectable } from "@angular/core";
import { UserLocalDataSource } from "../source/user-local-datasource";
import { UserBasicInfoModel } from "./dbo/user-info.model";
import CryptoJS from "crypto-js";
import { storageKey } from "@environments/constants";

@Injectable()
export class UserLocalDataSourceImp extends UserLocalDataSource {
  constructor() {
    super();
  }

  setUserInfo(email: string, role: string) {
    localStorage.setItem(
      "sigpa.userInfo",
      CryptoJS.AES.encrypt(
        JSON.stringify({
          role: role,
          email: email,
          set_date: Date.now(),
        }),
        storageKey
      ).toString()
    );
  }

  getUserInfo(): UserBasicInfoModel {
    const text = CryptoJS.AES.decrypt(
      localStorage.getItem("sigpa.userInfo") ?? "",
      storageKey
    );

    return JSON.parse(text.toString(CryptoJS.enc.Utf8));
  }

  clearUserInfo(): boolean {
    if (!!this.getUserInfo()) {
      localStorage.removeItem("sigpa.userInfo");
      return true;
    } else {
      return false;
    }
  }
}
