import { Injectable } from "@angular/core";
import { UseCase } from "@core-interface/use-case";
import { IntegrationEntity } from "@domain/entities/app/integration.entity";
import { UserRepository } from "@domain/repositories/user/user.repository";
import { Observable } from "rxjs";

@Injectable()
export class GetServiceByIdUseCase
  implements UseCase<number, IntegrationEntity>
{
  constructor(private userRepository: UserRepository) {}

  execute(params: number): Observable<IntegrationEntity> {
    return this.userRepository.getService(params);
  }
}
