<div
  [ngStyle]="{ 'width.px': size, 'height.px': size }"
  class="flex justify-center items-center text-secondary font-extrabold bg-color3-0.3 border-[2px] border-secondary-light uppercase rounded-full"
>
  <p *ngIf="initials; else noImg" [ngStyle]="{ 'font-size.px': textSize }">
    {{ initials }}
  </p>
</div>

<ng-template #noImg>
  <custom-icon
    icon="hide_image"
    [size]="textSize"
    class="text-secondary-light pt-1 pl-1"
  />
</ng-template>
